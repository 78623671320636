.hero-section {
  width: 100%;
  padding-top: 4rem;
  height: 95vh;
  background-image: url("/public/assets/images/hero-section-background.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  box-sizing: border-box;
  font-family: Manrope;
  position: relative;
  overflow: hidden;
  height: 100vw;
  max-height: 63.64263rem;
}

.hero-section-image {
  position: absolute;
  bottom: -20%;
  overflow: hidden;
}

.hero-section-heading {
  width: 58rem;
  color: #fff;
  text-align: center;
  font-family: Manrope;
  font-size: 4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; 
  letter-spacing: -0.12rem;
}

.hero-section-sub-text {
  width: 46.125rem;
  color: #fff;
  text-align: center;
  font-family: Manrope;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.03375rem;
}

.hero-button-container {
  margin: 1.5rem 0;
}

.hero-gif-payer-container {
  position: absolute;
  bottom: 0;
  width: 80vw;
  max-width: 900px;
}

.hero-gif-player-parent-container {
  width: 80vw;
  max-width: 900px;
  height: 50vw;
  max-height: 500px;
  border: 20px solid black;
  position: relative;
  bottom: 0px;
  border-bottom: 0;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background-color: #fff;
}

.hero-player-left-info-card {
  position: absolute;
  bottom: 40px;
  left: -180px;
  padding: 20px;
  border-radius: 20px;
  background-color: #f0ebff;
  width: 25%;
}

.hero-player-left-info-top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hero-player-left-info-top-section > p:first-child {
  font-size: 3rem;
  width: 50%;
  font-weight: 600;
}
.hero-player-left-info-top-section > p:last-child {
  font-size: 1rem;
  width: 50%;
  font-weight: 700;
}

.hero-player-left-info-bottom-section > p {
  font-size: 1rem;
  font-weight: 400;
}

.hero-player-right-info {
  position: absolute;
  bottom: 80px;
  right: -20px;
  width: 80vw;
  max-width: 900px;
  height: 50vw;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 900px) {
  .hero-section {
    padding: 4em 1rem;
    height: fit-content ;
    box-sizing: border-box;
  }

  .hero-section-heading {
    width: 85%;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: -0.0525rem;
  }

  .hero-section-sub-text {
    width: 75%;
    color: #fff;
    text-align: center;
    font-family: Manrope;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.02625rem;
  }

  .hero-section-image {
    display: none;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 900px) {
  .hero-section {
    height: 70vh ;
  }

  .hero-section-heading {
    font-size: 2.5rem;
    width: 70%;
  }

  .hero-section-sub-text {
    width: 65%;
    font-size: 1.2rem;
  }
  
  .hero-section-image {
    position: absolute;
    top: 30rem;
    width: 80vw;
  }
}

.hero-section-image img {
  width: 100%;
}
