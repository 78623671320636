.why-webless-section {
  width: 100%;
  padding: 8rem 5rem;
  background-color: #261751;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

.why-webless-section > .bottom-center-gradient {
  position: absolute;
  bottom: -50%;
  width: 62.31781rem;
  height: 62.31781rem;
  flex-shrink: 0;

  border-radius: 62.31781rem;
  background: rgba(111, 85, 255, 0.31);
  filter: blur(217px);
  z-index: 0;
}

.why-webless-section-heading {
  z-index: 1;
  width: 65%;
  color: #fff;
  text-align: center;
  font-family: Manrope;
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: -0.09rem;
}

.why-webless-cards-container {
  z-index: 1;
  margin-top: 3rem;
  display: flex;
  /* justify-content: center; */
  gap: 2rem;
}

.why-webless-card {
  width: 27%;
  padding: 1.5rem;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.why-webless-card-image-container {
  width: 100%;
  background-color: #eeebff;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 16/10;
  border-radius: 0.5rem;
}

.why-webless-card-heading {
  color: var(--black-100, #000);
  font-family: Manrope;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 1.925rem */
  letter-spacing: -0.0525rem;
}

.why-webless-card-desc {
  color: var(--black-100, #000);
  font-family: Manrope;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.03375rem;
}

@media only screen and (max-width: 900px) {
  .why-webless-section {
    padding: 4rem 0.5rem;
  }

  .why-webless-section-heading {
    padding: 0;
    width: inherit;
    font-size: 2rem;
  }

  .why-webless-cards-container {
    display: flex;
    justify-content: start;
    width: 100%;
    margin-top: 1rem;
    gap: 1rem;
    overflow-x: scroll;
    scrollbar-width: none;
  }

  .why-webless-card {
    width: 70%;
    padding: 1rem 1rem;
    flex-shrink: 0;
  }

  .why-webless-card-image-container {
    width: 100%;
  }

  .why-webless-card-heading {
    font-size: 1.5rem;
  }

  .why-webless-card-desc {
    font-size: 1rem;
  }
}
