.action-section {
  padding: 5vw 10vw;
  position: relative;
  overflow: hidden;
}

.action-section-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 0 10rem;
}

.action-section-heading {
  color: var(--black-100, #000);
  text-align: center;
  font-family: Manrope;
  font-size: 4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: -0.12rem;
}

.action-section-desc {
  padding: 0 10rem;
  color: var(--black-100, #000);
  text-align: center;
  font-family: Manrope;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.03375rem;
}

.top-right-gradient {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  border-radius: 15rem;
  background: rgba(111, 85, 255, 0.31);
  filter: blur(217px);
  width: 22.19756rem;
  height: 22.19756rem;
  flex-shrink: 0;
}
.bottom-left-gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
  border-radius: 22.19756rem;
  background: rgba(111, 85, 255, 0.31);
  filter: blur(217px);
  width: 15rem;
  height: 15rem;
  flex-shrink: 0;
}

@media only screen and (max-width: 900px) {
  .action-section {
     padding: 2rem 0;
  }

  .action-section-container{
    padding: 0.9rem 1rem;
  }

  .action-section-heading{
    font-size: 2rem;
    font-weight: 600;
    width: 75%;
    padding: 0;
  }

  .action-section-desc{
    width: 70%;
    padding: 0;
    font-weight: 500;
  }
}

@media only screen and (max-width: 1400px){
  .action-section {
    padding: 3rem 0;
  }
  .action-section-desc{
    padding: 0;
    width: 70%;
  }
}
