.login-page {
  background-color: #fff;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.login-page-top-right-gradient {
  position: absolute;
  top: -20%;
  right: -20%;
  width: 70.29969rem;
  height: 70.29969rem;
  border-radius: 75.29969rem;
  background: rgba(111, 85, 255, 0.11);
  filter: blur(82px);
}

.login-page-bottom-left-gradient {
  position: absolute;
  bottom: -20%;
  left: -20%;
  border-radius: 45.56819rem;
  position: absolute;
  background: #ffebfc;
  /* filter: blur(102px); */
}
