.info-section {
  background-color: #f0ebff;
  padding: 8vw 13vw;
  width: 100vwv;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  box-sizing: border-box;
}

.info-section-left-container {
  /* width: 60%; */
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
}

.info-section-heading {
  color: var(--black-100, #000);
  font-family: Manrope;
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: -0.09rem;
}

.info-section-desc {
  color: var(--black-100, #000);
  font-family: Manrope;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.045rem;
}

.info-section-btns-container {
  display: flex;
  gap: 10px;
}

.info-section-right-container {
  padding: 0;
  margin: 0;
  height: fit-content;
}

.info-section-right-container img {
  height: 30vw;
  aspect-ratio: 1;
  object-fit: cover;
}

@media only screen and (max-width: 900px) {
  .info-section {
    padding: 4rem 1.125rem;
    margin-bottom: 4rem;
  }

  .info-section-left-container {
    gap: 1rem;
  }

  .info-section-heading {
    font-size: 2rem;
    line-height: 110%;
  }
  .info-section-desc {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
}
