.connect-webless-section {
  margin: auto;
  padding: 4.5rem 0rem;
}

.company-logos-container {
  margin-top: 8vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 0 3rem;
}

.company-logos-container img {
  height: 3.5rem;
}

.connect-webless-section > p {
  color: var(--black-100, #000);
  text-align: center;
  font-family: Manrope;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.03375rem;
}

@media only screen and (max-width: 900px) {
  .connect-webless-section {
    padding: 3.4rem 0.7rem;
  }
  .company-logos-container {
    flex-wrap: nowrap;
    overflow: scroll;
    scrollbar-width: none;
    justify-content: start;
    padding: 0 0rem;
  }

  .company-logos-container img {
  height: 3rem;
}
}
