.chip-container {
  display: flex;
  align-items: center;
  gap: 0.5625rem;
  border-radius: 2rem;
  background-color: #f0ebff;
  color: #fff;
  font-weight: 500;
  padding: 0.5rem 1rem;
  width: fit-content;
}

.chip-container svg {
  fill: #5333b5;
  width: 1.375rem;
  height: 1.375rem;
}

.chip-container p {
  color: var(--black-100, #000);
  text-align: center;
  font-family: Manrope;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.chip-container.light {
  background-color: #fff;
}

@media only screen and (max-width: 900px) {
  .chip-container svg {
    width: 0.7525rem;
    height: 0.7525rem;
  }

  .chip-container p {
    font-size: 0.75rem;
  }

  .chip-container {
    padding: 0.27363rem 0.54731rem;
    gap: 0.30788rem;
  }
}
