.review-section {
  box-sizing: border-box;
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 4rem 0;
  margin-bottom: 8rem;
}

.review-container {
  display: flex;
  padding: 2.5rem 2rem;
  width: 60%;
  flex-direction: column;
  align-items: center;
  background-color: #eeebff;
  border-radius: 1rem;
  position: relative;
  /* justify-content: center; */
}

.review-text-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.review-text-heading {
  color: var(--black-100, #000);
  font-family: Manrope;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.02rem;
}

.review-subtext {
  color: var(--black-100, #000);
  font-family: Manrope;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.015rem;
}

.review-info-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  height: 4rem;
  width: 100%;
  /* background-color: red; */
}

.reviewer-info {
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 4rem;
  width: 100%;
  cursor: pointer;
  width: fit-content;
  /* background-color: red; */
}

.reviewer-info img {
  height: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  object-position: top;
  border-radius: 50%;
}

.review-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.review-container .company-info {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.review-info-container .company-info-heading {
  color: #111;
  font-family: Manrope;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.125rem; /* 112.5% */
  letter-spacing: -0.01rem;
}
.review-info-container .company-info-subtext {
  color: #111;
  font-family: Manrope;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: -0.01rem;
}

.buttons-container {
  display: flex;
  gap: 10px;
}

.buttons-container > div {
  padding: 10px;
  /* height: 50px; */
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0ebff;
  border-radius: 50%;
  font-size: 1.2rem;
}

.quote-symbol-container {
  position: absolute;
  bottom: -3.5rem;
  right: 3rem;
}

.reviewer-info.bottom{
  display: none;
}

@media only screen and (max-width: 600px) {
  .review-section {
    margin: 0;
    padding: 4rem 0.5rem;
    flex-direction: column;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    overflow: hidden;
    gap: 1rem;
    box-sizing: border-box;
  }

  .review-container {
    width: 100%;
    background: #261751;
    padding: 1.5rem;
    box-sizing: border-box;
  }

  .review-text-container {
    gap: 0rem;
  }

  .review-text-heading {
    font-size: 1.5rem;
    color: #fff;
    letter-spacing: -0.015rem;
  }

  .review-subtext {
    font-size: 1.5rem;
    font-weight: 400;
    color: #fff;
    letter-spacing: -0.015rem;
  }

  .review-info-container {
    display: none;
  }

  .brand-logo {
    display: none;
  }

  .quote-symbol-container {
    display: none;
  }

  .reviewer-info.bottom{
    display: flex
  }

  .reviewer-info {
    /* background-color: red; */
    width: fit-content;
    margin: auto;
    font-family: Manrope;
  }

  .company-info-heading {
    font-weight: 700;
    line-height: 1.125rem;
  }

  .company-info-subtext {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
  }
}
