.calendly-button {
  border: 1px solid #5333b5;
  border-radius: 56px;
  padding: 0.8rem 1.5rem;
  color: #5333b5;
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  gap: 12px;
  transition: all 200ms;
  font-family: Manrope;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  background-color: transparent;
}



.calendly-button.filled {
  border: 1px solid transparent;
  background-color: #5333b5;
  color: white;
}

.calendly-button.filled:hover {
  background: #5a3fdf;
}


.calendly-button.white {
  background-color: #fff;
  color: #5a3fdf;
}

.calendly-button.light:hover {
  background: #f0ebff;
}

.calendly-button.border {
  border: 1px solid #5333b5;
}

.calendly-button:hover {
  /* background-color: #5333B5; */
  background: #f0ebff;
  /* color: #000; */
  cursor: pointer;
}

.calendly-button > .upright-arrow {
  font-size: 1rem;
}

.button.white {
  background-color: white;
}

.button.white:hover {
  background: #f0ebff;
}

@media only screen and (max-width: 900px) {
  .button {
    padding: 0.7rem 1rem;
  }

  .button > p {
    font-size: 0.875rem;
  }
}
