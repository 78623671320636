.products-section {
  width: 100%;
  height: fit-content;
  padding: 5vw 10vw;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  box-sizing: border-box;
}

.products-section-heading {
  width: 60%;
  color: var(--black-100, #000);
  text-align: center;
  font-family: Manrope;
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 3.3rem */
  letter-spacing: -0.09rem;
}

.product-cards-container {
  width: 100%;
  margin-top: 5vw;
  display: flex;
  flex-direction: column;
  gap: 8rem;
  /* background-color: red; */
}

.product-card-container-wrapper {
  position: relative;
  overflow: hidden;
}

.product-card-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 3rem;
  height: fit-content;
}
/* 
.product-card-container-top-right-gradient {
  position: absolute;
  right: -10rem;
  top: -10rem;
  width: 22.19756rem;
  height: 22.19756rem;
  flex-shrink: 0;
  border-radius: 22.19756rem;
  background: rgba(111, 85, 255, 0.31);
  filter: blur(217px);
} */

.product-card-left-container {
  padding: 0;
  margin: 0;
  width: 50%;
  height: fit-content;
  /* background-color: blue; */
}

.product-card-left-container img {
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 10px;
  width: 100%;
}

.product-right-container {
  /* background-color: aqua; */
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  padding-right: 1rem;
  box-sizing: border-box;
}

.product-card-heading {
  color: var(--black-100, #000);
  font-family: Manrope;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 2.75rem */
  letter-spacing: -0.075rem;
}

.product-card-desc {
  color: var(--black-100, #000);
  font-family: Manrope;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.03375rem;
}

.product-card-btns-container {
  display: flex;
  gap: 10px;
}

/* @media (min-width: 600px) and (max-width: 800px) {
  .product-card-container {
    flex-direction: column;
  }
} */

@media only screen and (max-width: 900px) {
  .products-section {
    gap: 0.75rem;
    padding: 0.9rem 1rem;
    margin-bottom: 4rem;
  }

  .products-section-heading {
    width: 100%;
    padding: 0;
    font-size: 2rem;
  }

  .product-cards-container{
    gap: 4rem;
  }

  .product-card-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .product-card-left-container {
    width: 100%;
  }

  .product-right-container {
    width: 100%;
  }

  .product-card-heading {
    font-size: 2rem;
  }

  .product-card-desc {
    color: #3f3f3f;
    font-family: Manrope;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; 
  }

  .product-card-btns-container{
    /* background-color: red; */
  }
}
